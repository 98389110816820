import * as React from "react";
import {connect} from "react-redux";
import loadable from "@loadable/component";

const IntroContent = loadable(({fileName}) => import(`./intros/${fileName}.js`), {
    cacheKey: ({fileName}) => fileName
});

const IntroComponent = ({currentCodeData}) => {
    const [fileName, setFileName] = React.useState(null);

    React.useEffect(() => {
        setFileName(currentCodeData.introType + "IntroComponent");
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <>
            {currentCodeData != null && fileName && (<IntroContent fileName={fileName} introData={currentCodeData.introData}/>)}
        </>
    );
};

export default connect(state => ({
    currentCodeData: state.global.currentCode
}), null)(IntroComponent);
