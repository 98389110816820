import * as React from "react";
import {connect} from "react-redux";
import {navigate} from "gatsby";

// components
import DefaultLayoutComponent from "../../components/layouts/DefaultLayoutComponent";
import IntroComponent from "../../components/IntroComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLongArrowAltRight} from "@fortawesome/free-solid-svg-icons";

const DecisionIntroductionPage = ({isNewState, codeData, currentRound, totalRounds}) => {

    React.useEffect(() => {
        if (isNewState) {
            navigate('/');
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    const handleSubmit = () => {
        navigate("/code/decision", {replace: true});
    };

    return (
        <DefaultLayoutComponent currentRound={currentRound} totalRounds={totalRounds}>
            <div className="w-100 h-100 d-flex flex-column justify-content-between bg-dark p-3">
                <div className="title-wrapper text-center">
                    <h1 className="display-6 stone-dark mb-0">RENCONTRE</h1>
                    {/*{codeData != null && <h4 className="text-muted">{codeData.introData.encouteredCharacter}</h4>}*/}
                </div>
                {codeData != null && <IntroComponent/>}
                <div className="d-flex justify-content-end align-items-center">
                    <p className="text-white-50 mb-0">
                        <small>Continuer</small>
                    </p>
                    <button className="hk-btn hk-btn-dark ms-2" onClick={handleSubmit}>
                        <FontAwesomeIcon icon={faLongArrowAltRight}/>
                    </button>
                </div>
            </div>
        </DefaultLayoutComponent>
    )
};

export default connect(state => ({
    isNewState: state.global.codeList == null,
    currentRound: state.global.currentRound,
    totalRounds: state[state.global.type].totalRounds,
    codeData: state.global.currentCode
}), null)(DecisionIntroductionPage)
